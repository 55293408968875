import PropTypes from "prop-types";

import get from "lodash.get";

import storyCardFilter from "~/content/filters/story-card";
import storyCardAnglerfishFilter from "~/content/filters/story-card-anglerfish";

import { formatContentConfig } from "~/components/utilities/www-remover";
import { isVideoUrl } from "~/shared-components/story-card/_children/Video.helpers";

import { getArtSlotInfo } from "./utilities/index.js";
import { getSlideshowUrls } from "~/shared-components/story-card/_children/Slideshow.helpers";
import { getAnglerfishImageId } from "~/shared-components/story-card/_children/Image.helpers";

export const noContentConfig = {
  source: "empty"
};

const getLiveGraphicContentKey = (liveGraphicsType) => {
  // NOTE: if more liveGraphicsTypes , they need analogous code.
  switch (liveGraphicsType) {
    case "olympics":
      return "olympicsData";
    case "elex":
    default:
      return "electionData";
  }
};

export const getAncillaryContentConfig = ({
  overrides = {},
  overridesMobile = {},
  outputType = "default",
  isAdmin = false
}) => {
  const config = {};

  // START: noConfig
  config.noConfig = {};
  // END: noConfig

  // START: noContent
  config.noContent = noContentConfig;
  // END: noContent

  // START: liveGraphic
  const { artHide } = overrides;
  // NOTE: live graphics component takes the place of art slot, so figure out
  // if hasLiveGraphic, to prevent videoData if necessary

  let liveGraphicsHide;
  let liveGraphicsContentConfig;

  if (!artHide) {
    ({ liveGraphicsHide, liveGraphicsContentConfig = {} } = overrides);
  } else if (outputType === "jsonapp") {
    const { artHide: artHideMobile } = overridesMobile;
    if (!artHideMobile) {
      ({ liveGraphicsHide, liveGraphicsContentConfig = {} } = overridesMobile);
    }
  }
  const service = get(liveGraphicsContentConfig, "contentService", "");
  const hasLiveGraphics =
    !liveGraphicsHide && /^(.*)-live-graphic$/.test(service);

  if (hasLiveGraphics) {
    const liveGraphicsType = RegExp.$1;
    const liveGraphicsContentKey = getLiveGraphicContentKey(liveGraphicsType);
    config[liveGraphicsContentKey] = {
      source: service,
      query: get(liveGraphicsContentConfig, "contentConfigValues", {})
    };
  }
  // END: liveGraphic

  // START: videoData
  // NOTE: live graphics component takes precedence
  if (!hasLiveGraphics) {
    const { inlineVideo, alternateArt } = overrides;
    const hasVideo =
      !artHide && inlineVideo && alternateArt
        ? isVideoUrl(alternateArt)
        : false;
    if (hasVideo) {
      config.videoData = {
        source: "prism-promo",
        query: { content_path: alternateArt },
        filter: storyCardFilter
      };
    } else if (outputType === "jsonapp") {
      // NOTE: In theory, mobile and non-mobile could have different
      // ancillaryContentConfig. For now, the known case is when
      // non-mobile inlineVideo=false and mobile inlineVideo=true (via
      // moblePreset). Because all content fetchng must be done in a
      // single batch for jsonapp, this code needs to account for that case.
      //
      // If things get more complicated, this might not be good enough.
      //
      // A possible example would be if mobile and non-mobile used different
      // content config for the same kind of ancillary content. That is, if mobile
      // showed one video and non-mobile showed another. Then, they'd have to be
      // fetched using different keys and the rest of the code would have to be able
      // to deal with this.
      const {
        artHide: artHideMobile,
        inlineVideo: inlineVideoMobile,
        alternateArt: alternateArtMobile
      } = overridesMobile;
      const hasVideoMobile =
        !artHideMobile && inlineVideoMobile && alternateArtMobile
          ? isVideoUrl(alternateArtMobile)
          : false;
      if (hasVideoMobile) {
        config.videoData = {
          source: "prism-promo",
          query: { content_path: alternateArt },
          filter: storyCardFilter
        };
      }
    }
  }
  // END: videoData

  // START: podcastData
  // NOTE: if overrides.audioUrl ends with \.(mp3|ogg|wav) then no need for this
  // Extend the code to cover this case
  const { audioHide, audioUrl, audioAllowArticleType } = overrides;
  let { audioCanonicalUrl } = overrides;
  // NOTE: Don't bother fetching an article if audio article is not allowed
  if (
    /none/.test(audioAllowArticleType) &&
    !/\/podcasts\//.test(audioCanonicalUrl)
  ) {
    audioCanonicalUrl = "";
  }
  const hasPodcastByUrl =
    !audioHide &&
    (!audioUrl ||
      !audioUrl
        .split("?")[0]
        .match(/^https?:\/\/.*\.(mp3|ogg|wav)(\?|#|$)/i)) &&
    audioCanonicalUrl;

  if (hasPodcastByUrl) {
    config.podcastData = {
      source: "prism-promo",
      query: { content_path: audioCanonicalUrl },
      filter: storyCardFilter
    };
  }
  // END: podcastData

  // START: slideshowListOfData
  // NOTE: For now, not fetching slideshow images. Just
  // determining hasSlideshow to determine whether imageData
  // needs to be fetched.
  const { slideshowShow } = overrides;
  let hasSlideshow = false;
  // NOTE: live graphics component takes precedence
  // NOTE: See warnings below re: imageData about raw_caption. Same warnings apply here.
  if (!hasLiveGraphics) {
    if (!artHide && slideshowShow) {
      const list = getSlideshowUrls({ overrides });
      hasSlideshow = !!list?.length;
      if (isAdmin && hasSlideshow) {
        config.slideshowListOfData = [];
        list.forEach((url) => {
          const id = getAnglerfishImageId(url);
          config.slideshowListOfData.push(
            id
              ? {
                  source: "anglerfish",
                  query: { id },
                  filter: storyCardAnglerfishFilter
                }
              : config.noConfig
          );
        });
      }
    }
  }

  // START: imageData
  // NOTE: live graphics component takes precedence

  // NOTE: Anglerfish has raw_caption data. However, Fusion/React dumps
  // all fetched content into the page source. Exposing the raw_caption
  // to the public could put TWP into legal troubles. Hence, this code.
  // The caption is fetched in the admin so the editors have a starting
  // point for the caption. Once edited, the caption is stored on the
  // backing content. If unedited and published, there will be no caption.
  if (!hasLiveGraphics) {
    // NOTE: imageData only needed when the art slot is an image
    // that's not part of the content (basically, when it's alternateArt.
    // hence the check here for artSlotType == "alt-image"
    if (!artHide) {
      const artSlot = getArtSlotInfo({ overrides });
      const artSlotType = get(artSlot, "type", undefined);
      const anglerfishId = get(artSlot, "anglerfishId", undefined);
      const hasImage = artSlotType === "alt-image" && anglerfishId;
      // NOTE: Only fetch if isAdmin. See above.
      if (isAdmin && hasImage) {
        config.imageData = {
          source: "anglerfish",
          query: { id: anglerfishId },
          filter: storyCardAnglerfishFilter
        };
      }
    } else if (outputType === "jsonapp") {
      // NOTE: Same deal as for video
      const { artHide: artHideMobile } = overridesMobile;
      if (!artHideMobile) {
        const artSlot = getArtSlotInfo({ overrides: overridesMobile });
        const artSlotType = get(artSlot, "type", undefined);
        const anglerfishId = get(artSlot, "anglerfishId", undefined);
        const hasImage = artSlotType === "alt-image" && anglerfishId;
        // NOTE: Only fetch if isAdmin. See above.
        if (isAdmin && hasImage) {
          config.imageData = {
            source: "anglerfish",
            query: { id: anglerfishId },
            filter: storyCardAnglerfishFilter
          };
        }
      }
    }
  }
  // END: imageData

  // START: liveTickerData
  const { liveTickerHide, liveTickerCanonicalUrl } = overrides;
  if (!liveTickerHide && liveTickerCanonicalUrl) {
    config.liveTickerData = {
      source: "prism-promo",
      query: { content_path: liveTickerCanonicalUrl },
      filter: storyCardFilter
    };
  }
  // END: liveTickerData

  // NOTE: Prevents superfluous content fetches on web but
  // these can be fallbacks for jsonapp, so keep
  if (outputType !== "jsonapp" && hasSlideshow) {
    delete config.videoData;
    delete config.imageData;
  }

  return Object.keys(config).reduce((acc, key) => {
    acc[key] = formatContentConfig(config[key]);
    return acc;
  }, {});
};

getAncillaryContentConfig.propTypes = {
  props: PropTypes.object
};

/**
 * @param {array} items - items.length will could set the upper bound on limit
 * @param {object} overrides - object with two particular  fields to look for a limit
 *                    overrides.flexFeatureContentConfig.contentConfigValues.limit (default to 5)
 *                    overrides.limit (default to 5)
 *
 * @returns {int} - The minimum of the three values
 */
export const getFeedLimit = ({
  items = [],
  overrides = {},
  useOffset = true
}) => {
  // NOTE: This is to assure these values are numbers with proper defaults
  let feedLimit = get(
    overrides,
    "flexFeatureContentConfig.contentConfigValues.limit",
    5
  );
  let featureLimit = get(overrides, "limit", 5);
  feedLimit = !Number.isNaN(Number.parseInt(feedLimit, 10))
    ? Number.parseInt(feedLimit, 10)
    : 5;
  featureLimit = !Number.isNaN(Number.parseInt(featureLimit, 10))
    ? Number.parseInt(featureLimit, 10)
    : 5;

  let contentLimit = Math.max(items.length, 0);
  if (useOffset) {
    let offset = get(overrides, "offset", 0);
    offset = !Number.isNaN(Number.parseInt(offset, 0))
      ? Number.parseInt(offset, 0)
      : 0;
    contentLimit = Math.max(items.length - offset, 0);
  }

  return Math.min(contentLimit, feedLimit, featureLimit);
};

getFeedLimit.propTypes = {
  items: PropTypes.array,
  overrides: PropTypes.object
};
